import radioimg from "../../assets/radio.svg";
import checkimg from "../../assets/checkbox.svg";
import dropimg from "../../assets/dropdown.svg";
import textimg from "../../assets/textbox.svg";

export const questionOption = [
  {
    value: "radio",
    label: (
      <div className="d-flex align-items-center">
        <img src={radioimg} className="me-2" alt="" />
        <span>Multiple Choice</span>
      </div>
    ),
  },
  {
    value: "checkBox",
    label: (
      <div className="d-flex align-items-center">
        <img src={checkimg} className="me-2" alt="" />
        <span>Checkboxes</span>
      </div>
    ),
  },
  {
    value: "dropDownSingle",
    label: (
      <div className="d-flex align-items-center">
        <img src={dropimg} className="me-2" alt="" />
        <span>Dropdown with Single Select</span>
      </div>
    ),
  },
  {
    value: "dropDownMulti",
    label: (
      <div className="d-flex align-items-center">
        <img src={dropimg} className="me-2" alt="" />
        <span>Dropdown with Multiple Select</span>
      </div>
    ),
  },
  {
    value: "textField",
    label: (
      <div className="d-flex align-items-center">
        <img src={textimg} className="me-2" alt="" />
        <span>Text Box</span>
      </div>
    ),
  },
];

export const generateOptions = (limit) => {
  return Array.from({ length: limit - 1 }, (_, i) => {
    const value = String(i + 2).padStart(2, "0");
    return { value, label: value };
  });
};

export const OPTIONS = generateOptions(120);
