import React, { useContext } from "react";
import { Modal } from "antd";
import { ThemeContext } from "../../common/themeContext";

const VisibilityModal = ({ showVisibilityModal, setShowVisibilityModal,activeStatus,setActiveStatus }) => {
  const { theme } = useContext(ThemeContext);

  const handleModalYes = () => {
    setShowVisibilityModal(false);
    setActiveStatus((prevActiveStatus) => !prevActiveStatus);
  };

  const handleCancel = () => {
    setShowVisibilityModal(false);
  };

  return (
    <Modal
      className={
        theme === "healthCare" ? "healthcare mx550" : "primecare mx550"
      }
      open={showVisibilityModal}
      onCancel={handleCancel}
      onOk={handleModalYes}
      footer={[
        <button
          className="  py3 fw-18 gbo continuebtn savebtn cancelbtn me-20"
          onClick={handleCancel}
        >
          Cancel
        </button>,
        <button className="okbtn continuebtn" onClick={handleModalYes}>
          Yes
        </button>,
      ]}
    >
      <div className="mb-1 heedprodct text-center mt-3">Are you sure?</div>
      <p className="text-center">
        This will turn the feature off for this product
      </p>
      <hr />
    </Modal>
  );
};

export default VisibilityModal;
