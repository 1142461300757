import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import logo1 from "../../assets/HealthCare2u_Logo.svg";
import PCGS_Logo from "../../assets/PCGS_Logo.png";
import carousel from "../../assets/carousel.png";
import { useNavigate } from "react-router-dom";
import emailicon from "../../assets/mailicon.svg"
import lockicon from "../../assets/lockicon.svg"
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loginHandler, loginSliceAction } from "../../redux/action/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleKeyPressSpace } from "../../common/CommonFunctions";
import { validationSchemaLogin } from "../../common/Validations";
import { useCommonMessage } from "../../common/CommonMessage";
import Loader from "../Loder/loader";

const Login = () => {
  const messageApi = useCommonMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const loginSelector = useSelector((state) => state?.loginSliceDetails)

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    let payload = {
      email: values?.email,
      password: values?.password
    }
    dispatch(loginHandler(payload))
  };

  useEffect(() => {
    if (loginSelector?.data) {
      messageApi.open({
        type: 'success',
        content: loginSelector?.data?.message,
      });
      navigate("/dashboard")
      dispatch(loginSliceAction.loginDetailsSliceReset())
    } else if (loginSelector?.message) {
      messageApi.open({
        type: 'error',
        content: loginSelector?.message,
      });
      dispatch(loginSliceAction.loginDetailsSliceReset())
    }
  }, [loginSelector])

  return (
    <>
      {loginSelector?.isLoading === true && <Loader loaderTransform="loaderTransform" />}
      <Container
        fluid
        className="login-padding text-black hv-100 carousel-health"
      >
        <div className="h-100 dflex gap-4 w-100">
          <div className=" w-x-50 min700 d-flex align-items-center justify-content-center">
            <Carousel className="">
              <Carousel.Item>
                <Image className="h-100 w-100" src={carousel} />
              </Carousel.Item>
              <Carousel.Item>
                <Image className="h-100 w-100" src={carousel} />
              </Carousel.Item>
              <Carousel.Item>
                <Image className="h-100 w-100" src={carousel} />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="w-x-50 min700">
            <div className="d-flex align-items-center justify-content-center h-100 ">
              <div className="mx-700">
                <div className="text-center mb-60">
                  <img
                    src={logo1}
                    className=" healthcareImg"
                    alt="Healthcare Logo"
                  />
                  <img
                    src={PCGS_Logo}
                    className=" primecareImg"
                    alt="Primecare Logo"
                  />
                </div>
                <h1 className=" p-color gb fw-36 tcenter">Sign In</h1>
                <div className="fw-18 gm mb-40 stext tcenter">
                  Enter your details to Sign in
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchemaLogin}
                  onSubmit={(values, formikBag) => {
                    handleFormSubmit(values, formikBag);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      {/* Email Field */}
                      <div className="login-form formpadding mb-20 imgpo">
                        <label className="form-label " htmlFor="email">
                          Email*
                        </label>
                        <div className="position-relative">
                          <div className="line"></div>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                          className="form-control"
                          onKeyPress={handleKeyPressSpace}
                        />
                        <img src={emailicon} alt="" className="emailicon" />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      {/* Password Field */}
                      <div className="login-form formpadding mb-40 imgpo">
                        <label className="form-label " htmlFor="password">
                          Password*
                        </label>
                       <div className="position-relative">
                        <div className="line"></div>
                       <Field
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          className="form-control"
                          onKeyPress={handleKeyPressSpace}
                        />
                        <img src={lockicon} alt="" className="emailicon" />
                       </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      {/* Submit Button */}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-100 sbg border-0 py3 fw-18 gbo continuebtn"
                      >
                        {isSubmitting ? "Logging in..." : "Login"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
