import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleKeyPressSpace } from "../../common/CommonFunctions";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeaturesHandler } from "../../redux/action/getproductFeaturesSlice";
import Loader from "../Loder/loader";
import uploadimg from "../../assets/uploadicon.svg";
import eye from "../../assets/eye.svg";
import deleteicon from "../../assets/deleteicon.svg";
import { Checkbox, message, Switch, Upload } from "antd";
import { ThemeContext } from "../../common/themeContext";
import axios from "axios";
import { API_URL } from "../../services/http";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import {
  addProductFeatureAction,
  addProductFeaturesHandler,
} from "../../redux/action/addProductFeaturesSlice";
import { useCommonMessage } from "../../common/CommonMessage";
import VisibilityModal from "./VisibilityModal";
import {
  dpcExtraDiscountValidation,
  dpcExtraDiscountValidationSpanish,
} from "../../common/Validations";
import { getGroupProductFeatureHandler } from "../../redux/action/getGroupProductFeature";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";
import {
  updateGroupProductFeatureAction,
  updateGroupProductFeaturesHandler,
} from "../../redux/action/updateGroupProductFeature";
import { getItem } from "../../common/localStorage";

const EditDpcDiscountGroup = () => {
  const [fileUploaded, setFileUploaded] = useState("");
  const [manageSwitch, setManageSwitch] = useState(false);
  const [spanishFileUpload, setSpanishFileUpload] = useState("");
  const messageApi = useCommonMessage();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);

  const { Dragger } = Upload;
  const fileUploadDataSelector = useSelector((state) => state?.fileUploadData);
  const getAdminLoginId = getItem("adminId");
  const getProductId = getItem("productId");
  const groupId = getItem("groupId");

  const { brandId } = useContext(ThemeContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileUploadSelector = useSelector((state) => state?.fileUploadData);
  // For Group
  const getGroupProductFeatureContentSelector = useSelector(
    (state) => state?.getGroupProductFeatureContent
  );

  const updateGroupProductFeatureMainSelector = useSelector(
    (state) => state?.updateGroupProductFeatureMain
  );

  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    showUploadList: false,
    async beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error("You can only upload PDF files!");
        setFileUploaded("");
        return false;
      }
      const isSizeAccepted = file.size / 1024 / 1024 < 11;
      if (!isSizeAccepted) {
        message.error("File size exceeds 10MB limit!");
        setFileUploaded("");
        return false;
      }
      if (isPDF && isSizeAccepted) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", Number(getAdminLoginId));
        formData.append("brandId", brandId);
        formData.append(
          "path",
          `${
            "product/" +
            getGroupProductFeatureContentSelector?.data?.data?.productCode
          }/${getItem("featureName")}/${state?.name}/${
            state?.manageSwitch === false || manageSwitch === false
              ? "English"
              : "Spanish"
          }`
        );
        setLoading(true);
        axios
          .post(API_URL + "/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            dispatch(fileUploadAction.fileUploadInfoSuccess(response.data));
            if (state?.manageSwitch === false || manageSwitch === false) {
              setFileUploaded(file?.name);
            } else {
              setSpanishFileUpload(file?.name);
            }
          })
          .catch((error) => {
            dispatch(fileUploadAction.fileUploadInfoFailure(error));
          })
          .finally(() => {
            setLoading(false);
          });
        if (state?.manageSwitch === false || manageSwitch === false) {
          setFileUploaded(file?.name);
        } else {
          setSpanishFileUpload(file?.name);
        }
      }
      return false;
    },
  };
  const groupProductFeatureId = getItem("groupProductFeatureId");
  const getGroupProductFeatureFunction = () => {
    if (Number(groupId)) {
      let payload = {
        productId: Number(getProductId),
        groupId: Number(groupId),
      };
      let payload1 = {
        groupProductFeatureId: groupProductFeatureId,
      };
      dispatch(getGroupProductFeatureHandler(payload));
      dispatch(getGroupProductFeatureContentHandler(payload1));
    }
  };

  useEffect(() => {
    getGroupProductFeatureFunction();
  }, [state]);

  const handleFormSubmit = (values) => {
    const isEnglish = state?.manageSwitch === false || manageSwitch === false;
    const isSpanish = !isEnglish;

    const id = Number(groupProductFeatureId);
    const existingComponents =
      getGroupProductFeatureContentSelector?.data?.data?.content?.componants ||
      [];

    // Check if a component with the same name exists
    const existingComponentIndex = existingComponents.findIndex(
      (component) => component.name === state?.name
    );

    // Prepare the updated component
    const updatedComponent = {
      name: state?.name,
      english: isEnglish
        ? {
            url: values?.discountUrlEnglish || "",
            phone: values?.phoneNumberEnglish?.toString() || "",
            fileUrl:
              fileUploadSelector?.data?.data?.signedUrl ||
              filteredData?.english?.fileUrl ||
              "",
            filePreSigned:
              fileUploadSelector?.data?.data?.preSignedUrl ||
              filteredData?.english?.filePreSigned ||
              "",
            fileName: fileUploaded || filteredData?.english?.fileName || "",
            isValuePresent:
              state?.manageSwitch || manageSwitch === false ? true : false,
            isVirtualCare: values?.isVirtualCareEnglish,
            virtualCare: filteredData?.english?.virtualCare ||state?.english?.virtualCare,
          }
        : filteredData?.english || {
            url: state?.english?.url || "",
            phone: state?.english?.phone || "",
            fileUrl: state?.english?.fileUrl || "",
            filePreSigned: state?.english?.filePreSigned || "",
            fileName: state?.english?.fileName || "",
            isValuePresent: state?.english?.isValuePresent || false,
            isVirtualCare: values?.isVirtualCareEnglish,
            virtualCare: state?.english?.virtualCare,
          },
      spanish: isSpanish
        ? {
            url: values?.discountUrlSpanish || "",
            phone: values?.phoneNumberSpanish?.toString() || "",
            fileUrl:
              fileUploadSelector?.data?.data?.signedUrl ||
              filteredData?.spanish?.fileUrl ||
              "",
            filePreSigned:
              fileUploadSelector?.data?.data?.preSignedUrl ||
              filteredData?.spanish?.filePreSigned ||
              "",
            fileName:
              spanishFileUpload || filteredData?.spanish?.fileName || "",
            isValuePresent:
              state?.manageSwitch === true || manageSwitch === true
                ? true
                : filteredData?.spanish?.isValuePresent ||
                  state?.spanish?.isValuePresent,
            isVirtualCare: values?.isVirtualCareSpanish,
            virtualCare: filteredData?.spanish?.virtualCare ||state?.spanish?.virtualCare,
          }
        : filteredData?.spanish || {
            url: state?.spanish?.url || "",
            phone: state?.spanish?.phone || "",
            fileUrl:
              state?.manageSwitch === true || manageSwitch === true
                ? fileUploadSelector?.data?.data?.signedUrl
                : state?.spanish?.fileUrl,
            filePreSigned:
              state?.manageSwitch === true || manageSwitch === true
                ? fileUploadSelector?.data?.data?.preSignedUrl
                : state?.spanish?.filePreSigned,
            fileName: state?.spanish?.fileName || "",
            isValuePresent:
              state?.manageSwitch === true || manageSwitch === true
                ? true
                : filteredData?.spanish?.isValuePresent ||
                  state?.spanish?.isValuePresent,
            isVirtualCare: values?.isVirtualCareSpanish,
            virtualCare: state?.spanish?.virtualCare,
          },
      status: activeStatus === true ? "ACTIVE" : "INACTIVE",
      isSelected: true,
      language: isEnglish ? "english" : "spanish",
      spanishName:state?.spanishName
    };

    let components = [...existingComponents];
    if (existingComponentIndex >= 0) {
      components[existingComponentIndex] = {
        ...existingComponents[existingComponentIndex],
        ...updatedComponent,
      };
    } else {
      components.push(updatedComponent);
    }

    const payload = {
      id,
      content: {
        type: getGroupProductFeatureContentSelector?.data?.data?.content?.type,
        componants: components,
      },
    };
    dispatch(updateGroupProductFeaturesHandler(payload));
  };

  const handleEnglishSwitch = (resetForm) => {
    setManageSwitch(false);
    getGroupProductFeatureFunction();
    resetForm();
    setSpanishFileUpload("");
  };

  const handleSpanishSwitch = (resetForm) => {
    setManageSwitch(true);
    getGroupProductFeatureFunction();
    resetForm();
    setFileUploaded("");
  };

  const handleDeleteFile = () => {
    const isEnglish = state?.manageSwitch === false || manageSwitch === false;
    const isSpanish = !isEnglish;

    const existingComponents =
      getGroupProductFeatureContentSelector?.data?.data?.content?.componants ||
      [];

    const components = existingComponents.map((element) => {
      if (element?.name === state?.name) {
        return {
          ...element,
          english: isEnglish
            ? {
                ...element.english,
                fileUrl: "",
                filePreSigned: "",
                fileName: "",
                isValuePresent: true,
                isVirtualCare:element?.english?.isVirtualCare,
                virtualCare: element?.english?.virtualCare
              }
            : element.english, // Keep English data unchanged if not deleting
          spanish: isSpanish
            ? {
                ...element.spanish,
                fileUrl: "",
                filePreSigned: "",
                fileName: "",
                isValuePresent: true,
                isVirtualCare:element?.spanish?.isVirtualCare,
                virtualCare:element?.spanish?.virtualCare,
              }
            : element.spanish, // Keep Spanish data unchanged if not deleting
        };
      }
      return element; // Keep other components unchanged
    });

    const payload = {
      id: Number(groupProductFeatureId),
      content: {
        type: getGroupProductFeatureContentSelector?.data?.data?.content?.type,
        componants: components, // Updated components array after deletion
      },
    };

    // Dispatch the updated payload
    // dispatch(addProductFeaturesHandler(payload));
    setFilteredData(payload);

    if (isEnglish) {
      setFileUploaded("");
    } else if (isSpanish) {
      setSpanishFileUpload("");
    }

    // Reset upload info
    dispatch(fileUploadAction.fileUploadInfoReset());
  };

  useEffect(() => {
    if (updateGroupProductFeatureMainSelector?.data?.statusCode === 200) {
      messageApi.open({
        type: "success",
        content: updateGroupProductFeatureMainSelector?.data?.message,
      });
      getGroupProductFeatureFunction();
      dispatch(fileUploadAction.fileUploadInfoReset());
      dispatch(
        updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset()
      );
      navigate("/dpc-extra-group");
    } else if (updateGroupProductFeatureMainSelector?.message) {
      messageApi.open({
        type: "error",
        content: updateGroupProductFeatureMainSelector?.message,
      });
      dispatch(
        updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset()
      );
    }
  }, [updateGroupProductFeatureMainSelector, manageSwitch, state]);

  useEffect(() => {
    const data =
      getGroupProductFeatureContentSelector?.data?.data?.content?.componants?.find(
        (item) => item?.name === state?.name
      );

    setFilteredData(data);
  }, [
    getGroupProductFeatureContentSelector?.data?.data?.content?.componants,
    state?.name,
  ]);

  useEffect(() => {
    if (filteredData?.status === "ACTIVE") {
      setActiveStatus(true);
    } else if (filteredData?.status === "INACTIVE") {
      setActiveStatus(false);
    }
  }, [filteredData]);

  const handleSwitchChange = () => {
    setShowVisibilityModal(true);
  };

  return (
    <>
      {(getGroupProductFeatureContentSelector?.isLoading === true ||
        fileUploadDataSelector?.isLoading === true ||
        updateGroupProductFeatureMainSelector?.isLoading === true ||
        loading) && <Loader loaderTransForm="loaderTransForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>

          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.GROUPS,
                onClick: () => navigate("/groups"),
              },
              {
                text: BreadCrum.MANAGE_PRODUCTS,
                onClick: () => navigate("/edit-group-product"),
              },
              {
                text:
                  getGroupProductFeatureContentSelector?.data?.data?.content
                    ?.type || BreadCrum.DPC_EXTRA,
                onClick: () => navigate("/dpc-extra-group", { state: state }),
              },
              {
                text: state?.name,
                active: true,
              },
            ]}
          />

          <Formik
            enableReinitialize
            initialValues={{
              discountUrlEnglish: state?.english?.url || "",
              discountUrlSpanish: state?.spanish?.url || "",
              phoneNumberEnglish: state?.english?.phone || "",
              phoneNumberSpanish: state?.spanish?.phone || "",
              isVirtualCareEnglish: state?.english?.isVirtualCare || false,
              isVirtualCareSpanish: state?.spanish?.isVirtualCare || false,
            }}
            validationSchema={
              state?.name === "Telemedicine"
                ? null
                : manageSwitch === false
                ? dpcExtraDiscountValidation
                : dpcExtraDiscountValidationSpanish
            }
            onSubmit={(values, formikBag) => {
              handleFormSubmit(values, formikBag);
            }}
          >
            {({ values, resetForm }) => (
              <>
                <Form>
                  <div className="switches-container mb-30">
                    <div
                      className="mempro"
                      onClick={() => handleEnglishSwitch(resetForm)}
                    >
                      English
                    </div>
                    <div
                      className="mempro"
                      onClick={() => handleSpanishSwitch(resetForm)}
                    >
                      Spanish
                    </div>
                    <div
                      className={
                        !manageSwitch
                          ? "switch-wrapper"
                          : "switch-wrapper switchside"
                      }
                    >
                      <div className="switch">
                        {!manageSwitch ? (
                          <div>English</div>
                        ) : (
                          <div>Spanish</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="redirectionlink d-n one">
                    <div className="sectionpadding d-block mb-30 hoversection btnPosition">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 heedprodct">{state?.name}</p>
                        <Switch
                          className="switches"
                          checked={activeStatus}
                          onChange={handleSwitchChange}
                        />
                      </div>
                      <hr />

                      <div className="login-form">
                        {state?.name !== "Telemedicine" && (
                        <div className="mb-30 d-flex fullwidth gap-20">
                          <div>
                            <Field
                              type="text"
                              placeholder="URL"
                              name={
                                manageSwitch === false
                                  ? "discountUrlEnglish"
                                  : "discountUrlSpanish"
                              }
                              className="form-control w-md-50"
                              onKeyPress={handleKeyPressSpace}
                              autoComplete="Off"
                            />
                            <ErrorMessage
                              name={
                                manageSwitch === false
                                  ? "discountUrlEnglish"
                                  : "discountUrlSpanish"
                              }
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="">
                            <Field
                              type="text"
                              name={
                                manageSwitch === false
                                  ? "phoneNumberEnglish"
                                  : "phoneNumberSpanish"
                              }
                              placeholder="Phone Number"
                              className="form-control w-md-50"
                              onKeyPress={handleKeyPressSpace}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "ArrowUp" ||
                                  event.key === "ArrowDown"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              autoComplete="Off"
                            />
                            <ErrorMessage
                              name={
                                manageSwitch === false
                                  ? "phoneNumberEnglish"
                                  : "phoneNumberSpanish"
                              }
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        )}
                        {state?.name === "Telemedicine" && (
                        <>
                          {manageSwitch === false ? (
                            <Field type="checkbox" name="isVirtualCareEnglish">
                              {({ field }) => (
                                <Checkbox {...field} checked={field.value}>
                                  Virtual Care (English)
                                </Checkbox>
                              )}
                            </Field>
                          ) : (
                            <Field type="checkbox" name="isVirtualCareSpanish">
                              {({ field }) => (
                                <Checkbox {...field} checked={field.value}>
                                  Virtual Care (Spanish)
                                </Checkbox>
                              )}
                            </Field>
                          )}
                        </>
                      )}
                      </div>
                      {manageSwitch === false ? (
                        <>
                          {!(
                            fileUploaded || filteredData?.english?.fileName
                          ) ? (
                            <Field name="file_upload">
                              {({ field }) => (
                                <Dragger {...props}>
                                  <p className="ant-upload-drag-icon">
                                    <img src={uploadimg} alt="" />
                                  </p>
                                  <p className="ant-upload-text">
                                    <u>Click here</u> or drag & drop to upload
                                    .PDF file
                                  </p>
                                </Dragger>
                              )}
                            </Field>
                          ) : (
                            <div className="ant-upload-list ant-upload-list-text">
                              <span className="ant-upload-list-item-container">
                                <div className="d-flex gap-2 align-items-center file-icon">
                                  <img src={uploadimg} alt="" />
                                  {fileUploaded ||
                                    filteredData?.english?.fileName}
                                </div>
                                <div className="d-flex gap-2">
                                  <a
                                    href={
                                      fileUploadSelector?.data?.data
                                        ?.signedUrl ||
                                      filteredData?.english?.fileUrl
                                    }
                                    target="_blank"
                                  >
                                    <img src={eye} alt="" />
                                  </a>
                                  <div
                                    className="cursorPointer"
                                    onClick={handleDeleteFile}
                                  >
                                    <img src={deleteicon} alt="" />
                                  </div>
                                </div>
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {!(
                            spanishFileUpload || filteredData?.spanish?.fileName
                          ) ? (
                            <Field name="file_upload">
                              {({ field }) => (
                                <Dragger {...props}>
                                  <p className="ant-upload-drag-icon">
                                    <img src={uploadimg} alt="" />
                                  </p>
                                  <p className="ant-upload-text">
                                    <u>Click here</u> or drag & drop to upload
                                    .PDF file
                                  </p>
                                </Dragger>
                              )}
                            </Field>
                          ) : (
                            <div className="ant-upload-list ant-upload-list-text">
                              <span className="ant-upload-list-item-container">
                                <div className="d-flex gap-2 align-items-center file-icon">
                                  <img src={uploadimg} alt="" />
                                  {spanishFileUpload ||
                                    filteredData?.spanish?.fileName}
                                </div>
                                <div className="d-flex gap-2">
                                  <a
                                    href={
                                      fileUploadSelector?.data?.data
                                        ?.signedUrl ||
                                      filteredData?.spanish?.fileUrl
                                    }
                                    target="_blank"
                                  >
                                    <img src={eye} alt="" />
                                  </a>
                                  <div
                                    className="cursorPointer"
                                    onClick={handleDeleteFile}
                                  >
                                    <img src={deleteicon} alt="" />
                                  </div>
                                </div>
                              </span>
                            </div>
                          )}
                        </>
                      )}

                      <div className="text-end">
                        <button
                          className="sbg border-0 py3 fw-18 gbo continuebtn disclaimerBtn savebtn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
      {showVisibilityModal && (
        <VisibilityModal
          showVisibilityModal={showVisibilityModal}
          setShowVisibilityModal={setShowVisibilityModal}
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
        />
      )}
    </>
  );
};

export default EditDpcDiscountGroup;
